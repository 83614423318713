export const PRICE_MATCH_GUARANTEE_TITLE = "Price Match Guarantee";
export const PRICE_MATCH_GUARANTEE_SUBTITLE = "";
export const PRICE_MATCH_GUARANTEE_BODY = `<p>Most flight, hotel, and rental car purchases made through Capital One Business Travel are eligible for price matches if you find a cheaper itinerary elsewhere and let us know within 24 hours of booking through Capital One Business Travel. Availability of this service may vary, and not all services are available to all customers or on all bookings. Capital One Business Travel may revise these Terms and Conditions at any time by updating this posting.</p>
<ol>
<li><strong>Qualification criteria.</strong> To qualify for Capital One Business Travel’s price match guarantee, you must keep your original itinerary and the cheaper itinerary found must be:
<ol type=a>
<li>An exact match to the original itinerary that was made within the last 24 hours (including the cancellation policy, i.e. refundable or non-refundable);</li>
<li>Priced in USD, including any taxes and fees;</li>
<li>Available to the general public. For example, this means that the rate doesn’t require the customer to log into a site or have a special membership;</li>
<li>Available at the time the customer contacts us. Our agent must be able to verify that the lower price exists.</li>
</ol>
</li>
<li><p><strong>Submitting a claim.</strong>  You must submit a price match guarantee claim through an agent by calling Capital One Business Travel at 833-233-5660. Please provide the trip ID for the original booking, the amount of the lower price, and the website where you found the lower price so that our agents can verify the lower price. If the claim is verifiable, our agents will authorize the refund live on the phone.</p>
<p>If our agents are unable to verify the lower price, we will verify offline and will attempt to respond within 24 hours, though it may take longer in certain cases. If the claim is verifiable, you will receive an email confirmation of your refund. If the claim is not verifiable after an offline review, you will be notified of the decline by email, and you may not receive a refund. </p>
</li>
<li><p><strong>Refund method and amount.</strong> For reservations that meet all price match guarantee criteria, we will refund you 100% of the difference in price to your original payment method (i.e., credit card or rewards redemption). If you used a credit card and redeemed rewards to purchase the fare, the difference will be refunded proportionally, up to the benefit maximum.</p>
</li>
<li><p><strong>Refund timing and rewards adjustments.</strong> Refunds should appear on your account within 1-2 billing cycles. Please be aware that the portion of the transaction that is refunded will result in an adjustment of any rewards earned on that transaction.</p>
</li>
</ol>`;

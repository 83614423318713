import React from "react";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  isAgentPortal: boolean;
}

const defaultProps: Partial<IAxiosInterceptors> = {
  isAgentPortal: false,
};

const AxiosInterceptors = (props: IAxiosInterceptors) => {
  const { children } = props;

  return <>{children}</>;
};

AxiosInterceptors.defaultProps = defaultProps;

export default AxiosInterceptors;

import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import { IntlProvider } from "react-intl";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { store } from "./store";
import { TermsModuleProps, installColorConfig } from "redmond";
import ExperimentsProvider from "./context/experiments";
import fetchUserInfo from "./api/v1/user/fetchUserInfo";
import { RewardsBanner } from "./modules/rewards/components";
import AxiosInterceptors from "./components/AxiosInterceptors";
import { TermsClientAssetProps } from "redmond/terms-module/config";
import UserSourceProvider from "./context/userSource";
import { config } from "./api/config";

const Body = require(`./components/Body/${config.TENANT}/Body`).default;

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptTermsModule",
  seed: "ptTermsModule",
});

export const ClientContext = React.createContext<
  Partial<TermsClientAssetProps>
>({});

const App = (props: TermsModuleProps) => {
  const { theme, language, colors, baseHistory, experiments, clientAssets } =
    props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <UserSourceProvider>
          <Router history={baseHistory}>
            <AxiosInterceptors />
            <ClientContext.Provider value={{ ...clientAssets, sessionInfo }}>
              <div className="App">
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={activeTheme}>
                    {messages != null ? (
                      <IntlProvider
                        locale={locale}
                        defaultLocale="en"
                        messages={messages}
                      >
                        <Route path="*">
                          <RewardsBanner />
                        </Route>
                        <Body language={language} />
                      </IntlProvider>
                    ) : (
                      <div>Loading</div>
                    )}
                  </ThemeProvider>
                </StylesProvider>
              </div>
            </ClientContext.Provider>
          </Router>
        </UserSourceProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export default App;

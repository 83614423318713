import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentVariant, ExperimentState } from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments
// export const EXAMPLE = "c1-example";

export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";

export const TRAVEL_WALLET_CREDIT_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";

export const PREMIER_COLLECTION_EXPERIMENT =
  "c1-marketplace-luxury-hotels-collection";

export const TREES_EXPERIMENT = "c1-marketplace-trees";

export const HOTELS_CFAR = "c1-fintech-hotel-cfar";
export const HOTELS_PRICE_FREEZE = "c1-fintech-pf-hotel";

export const HOTEL_PRICE_DROP_SMOKE_TEST = "cap1-fintech-hotel-pd-smoke-test";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";

// Variants for cap1-fintech-hotel-pd-smoke-test
export const HOTEL_PRICE_DROP_SMOKE_TEST_25_DOLLAR = "25-dollar-pd-credit";
export const HOTEL_PRICE_DROP_SMOKE_TEST_FORCE_25_DOLLAR =
  "force-25-dollar-pd-credit";
export const HOTEL_PRICE_DROP_SMOKE_TEST_10_DOLLAR = "10-dollar-pd-credit";
export const HOTEL_PRICE_DROP_SMOKE_TEST_50_DOLLAR = "50-dollar-pd-credit";
export const HOTEL_PRICE_DROP_SMOKE_TEST_VARIANTS = [
  CONTROL,
  HOTEL_PRICE_DROP_SMOKE_TEST_25_DOLLAR,
  HOTEL_PRICE_DROP_SMOKE_TEST_FORCE_25_DOLLAR,
  HOTEL_PRICE_DROP_SMOKE_TEST_10_DOLLAR,
  HOTEL_PRICE_DROP_SMOKE_TEST_50_DOLLAR,
] as const;

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment> = [],
  experimentId: String,
  defaultExperimentVariant: ExperimentVariant = ExperimentVariant.CONTROL
): ExperimentVariant {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return null;
  });

  return (experiment?.variant as ExperimentVariant) ?? defaultExperimentVariant;
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export enum ActiveExperiments {}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result: ExperimentState) => {
          setState(result);
        });
      };
      fetchExperiments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;

import {
  CORP_DISRUPTION_PROTECTION_BODY,
  FLIGHT_CANCEL_FOR_ANY_REASON_BODY,
  HOTEL_CFAR_BODY,
  HOTEL_CFAR_TABLE,
  HOTEL_CFAR_TITLE,
  WEBSITE_TERMS_OF_USE_BODY,
} from "redmond";
import { CORP_TRAVEL_CREDITS_BODY } from "./travelCreditsTextConstant";

export const TERMS_OF_SERVICE_TITLE =
  "Capital One Business Travel Terms and Conditions";
export const TERMS_OF_SERVICE_SUBTITLE = "";

export const TERMS_OF_SERVICE_BODY = (isHotelCfarEnabled: boolean) => `
<p>
  Thank you for using the Capital One Business Travel website and mobile properties. The term
  "Capital One" in these Terms and Conditions means Capital One, N.A. The terms “Capital One Business Travel
  or "we" or "us" or "our" in these Terms and Conditions means Capital One Business Travel
   which is a service of Capital One, N.A., with offices at 1680 Capital One Drive, McLean,
  Virginia. Capital One Business Travel provides the materials contained on this website and
  mobile properties on the World Wide Web, which hereinafter will be referred to as the “Sites,” as
  a service to our customers and visitors. Capital One Business Travel requires that all
  visitors to the Sites adhere to the following Terms and Conditions. By accessing the Sites and any
  of its pages, you indicate your acknowledgement and acceptance of the Terms and Conditions set
  forth below without limitation or qualification.
</p>
<p>
  You acknowledge that the Capital One Business Travel platform is currently a pilot program in
  the Beta testing phase, and may have ongoing changes, errors, or inconsistencies. Capital One
  reserves the right to test, change, or add features and terms throughout the Beta testing phase.
</p>
<p>
  From time to time, we may change these terms. We recommend that you review them on a regular
  basis.
</p>
<p>
  Some of the services made available through Capital One Business Travel are offered by Hopper
  (USA) Inc. and its affiliated companies (“Hopper”). Your use of the services offered by Hopper and
  other third-parties that may be linked to from the Sites will be governed by their respective
  Terms and Conditions and Privacy Policies.
</p>
<h3><i>General Booking Terms and Condition</i></h3>
<ul>
  <li>
    <strong>Payment methods. </strong>Customers with a US-issued, eligible Capital One business card
    can book travel reservations for flights, hotels, and car rentals on the Sites and may pay via
    reward redemptions, travel credits, and/or an eligible Capital One business card. Reward
    redemptions that are included as part of your transaction will be deducted from your reward
    program account. Travel credit usages that are included as part of your transaction will be
    deducted. Payments by credit card will appear on your monthly card statement as “COT*[Trip
    Component]*BIZ*[TripID]*”. You may view the specific airline, hotel, or car rental supplier you
    booked with (each, a "Supplier" and collectively, "Suppliers") on the Sites under My Trips.
  </li>
  <li>
    <strong>Payment issues and fraud. </strong>Capital One Business Travel may cancel the
    booking in the event of non-payment, payment dispute, rewards dispute, fraud, or suspected fraud
    and penalties imposed by Suppliers, up to the full amount of each ticket/reservation, may be
    applied.
  </li>
  <li>
    <strong>Rules and regulations. </strong>All bookings made through Capital One Business Travel
    shall comply with all applicable governmental laws, rules and regulations including,
    without limitation, sanctions issued by the Office of Foreign Assets Control. Such sanctions may
    prevent Capital One Business Travel from offering travel to specific destinations or
    individuals. To assist Capital One Business Travel with its compliance with laws, rules and
    regulations, Capital One Business Travel may ask you for additional information. Any booking
    made, or in good faith believed to be made, in violation of applicable law, will be canceled by
    Capital One Business Travel, in our sole discretion, with no liability to you other than to
    issue a refund, if permitted by law.
  </li>
  <li>
    <strong>Cancelations and changes.</strong> Reservations are subject to the rules of each
    Supplier on your itinerary; some reservations cannot be canceled for a refund. Itinerary changes
    are subject to Capital One Business Travel or Supplier imposed cancelation or change fees
    plus applicable fare differential. Refunds, if permitted, may take up to 90 days to process and
    are subject to the policies of the individual Supplier. All refund requests must be submitted
    within 30 days after the scheduled departure date and any negotiable documents (i.e. airline
    tickets, redeemable certificates or vouchers, etc.) issued must be returned to Capital One
    Business Travel prior to processing any refund. Refunds will be returned via original
    payment method, with split-pay transactions using both credit card and rewards or Capital One
    statement credits being refunded proportionally. Please note: The cancelation policy for
    reservations made through Capital One Business Travel may differ from the cancelation policy
    for reservations made directly with the Supplier.
  </li>
  <li>
    <strong>Phone booking fee. </strong>If you book via phone, you may be subject to a $25 phone
    booking fee for each flight itinerary you book through the phone. Our travel offerings are
    optimized for digital self-servicing, and we strongly encourage you to book online for the best
    experience. If you cannot book digitally due to needs for accessibility accommodations, language
    barriers, or web issues, or you are experiencing certain emergency situations, the phone booking
    fee may be waived.
  </li>
</ul>
<h3><i>Who Can Use Capital One Business Travel</i></h3>
<p>
  In order to use Capital One Business Travel, the primary cardholder of the corresponding
  business card account must opt into using this business-grade travel management platform. The
  primary cardholder, or another cardholder on the account delegated by the account primary as the
  Onboarding Admin, must set up the business’s Capital One Business Travel policies and users.
  The Onboarding Admin will then be able to turn on all other users’ access to the business travel
  platform.
</p>
<p>
  By using Capital One Business Travel, you represent and warrant that any profile information
  you submit is true and accurate, that you are 18 years of age or older and fully able and
  competent to enter into and abide by these Terms and Conditions, are an eligible Capital One
  business card holder, and are digitally enrolled (i.e., have an online login to a Capital One
  account).
</p>
<p>
  The features on Capital One Business Travel (a.k.a., the business travel platform) may differ
  from those available on the consumer travel booking website (a.k.a., Capital One Business Travel).
</p>
<p>
  If you are a cardholder on a business card account enrolled in Capital One Business Travel:
</p>
<ul>
  <li>
    You will be able to book travel on the business travel platform with your business card,
    provided that you have not been deactivated by a travel administrator.
  </li>
  <li>
    You will be able to book travel on the consumer travel booking website (a.k.a., Capital One
    Travel) if and only if you have a separate Capital One card that has access to the consumer
    travel booking website.
  </li>
</ul>
<p>
  Except as otherwise expressly provided in these Terms and Conditions, you shall not utilize
  Capital One Business Travel to provide travel to non-employees or consumers (e.g. utilizing
  Capital One Business Travel in connection with your business as a travel agency). You may only
  use Capital One Business Travel to make legitimate reservations or purchases for you or other
  persons for whom you are authorized to act. You represent and warrant that you are not using
  Capital One Business Travel on behalf of any other entity, or for any commercial purpose. You
  shall not use Capital One Business Travel to make any speculative, false, fictitious, or
  fraudulent reservations.
</p>
<p>
  We reserve the right to refuse access to Capital One Business Travel or to limit or take away
  your access at any time and may take all available legal recourse for actual or suspected
  violations of these Terms and Conditions, including cancelation of bookings and closure of your
  Capital One card account. Any delay by Capital One Business Travel in taking such actions does
  not constitute a waiver of Capital One Business Travel’s rights to enforce these Terms and
  Conditions. By making Capital One Business Travel and the Sites available for your use,
  Capital One Business Travel does not consent to act as your agent or fiduciary. Use of our
  services is void where prohibited.
</p>
<p>
  As a condition of your use of the Sites, you warrant that you will not use the Sites for any
  purpose that is unlawful or prohibited by these Terms and Conditions.
</p>
<h3><i>Earning Rewards and Credits</i></h3>
<p>
  When you book with an eligible Capital One business card offering elevated rewards on Capital One
  Business Travel purchases, your business’s primary cardholder will earn elevated rewards as
  detailed below. Not all Capital One-issued credit cards offer elevated rewards for purchases made
  through Capital One Business Travel. Earnings will apply to net purchases (purchases minus any
  credits or returns) only. Purchases made outside of the Capital One Business Travel booking
  sites, including but not limited to hotel incidentals, upgrades, or other expenses, will not earn
  the elevated rewards, but will receive the standard purchase earn rate. Please note, only the
  primary cardholder will have access to view or redeem business rewards.
</p>
<ul>
  <li>
    <strong>Spark Miles, Spark Miles Select: </strong>5 miles per dollar for hotel and rental car
    purchases
  </li>
  <li>
    <strong
      >Spark Cash, Spark Cash Plus, Spark Cash Preferred, Spark Pro, Spark Pro charge card, Spark
      Cash Select</strong
    ><strong>:</strong> 5% cash back on hotel and rental car purchases
  </li>
</ul>
<h3><i>Managing Business Travel</i></h3>
<ul>
  <li>
    <strong>Travel management capabilities. </strong>Beyond booking travel, Capital One Business Travel
    empowers business cardholders to manage and analyze employees’ business travel spend.
    Capital One reserves the right to change and improve travel management capabilities at any time.
  </li>
</ul>
<ul>
  <li>
    <strong>Users.</strong> The Users tab allows Admins to edit an employee’s travel role and/or
    policy tier. Admins may deactivate users, which would prevent the user from accessing Capital
    One Business Travel. Admins may also reactivate users to re-establish that user’s access to
    the business travel platform. The Users tab is automatically populated with digitally-enrolled
    cardholders associated with the business card account.
  </li>
  <li>
    <strong>Policies. </strong>The Policies tab allows travel admins to set maximum price caps for
    in-policy bookings, across flights, hotels, and rental cars. Admins can set policies for the
    default “associate” policy tier, and also have the option to set separate caps for an
    “executive” policy tier.
  </li>
  <li>
    <strong>Out of Policy.</strong> The Out of Policy tab is where admins can review all
    out-of-policy bookings. Admins will be able to see any business justification Travelers provide,
    and can call 844-422-6922 to cancel bookings for at least 24 hours after the original booking
    was made. If the out-of-policy booking is canceled, travelers will automatically get notified by
    email..
  </li>
  <li>
    <strong>All Trips. </strong>The All Trips tab will show all business travel booked on the
    business card account, across all travelers. Admins will be able to sort and search by trip
    date, booking type, airlines, hotel names, rental car companies, traveler name, and trip status.
    Trip status filters will allow Admins to see which trips are happening at the moment, versus
    future trips and past trips.
  </li>
  <li>
    <strong>Reports. </strong>The Reports tab will allow admins to view and analyze spend across
    Capital One Business Travel. Reports include a dashboard view of the number of trips taken,
    total spend across flights, hotel, and rental cars, and travel spend insights. Reports are
    subject to change. Admins can download a CSV file with travel transaction data from the Reports
    tab.
  </li>
</ul>
<ul>
  <li>
    <strong>Travel roles. </strong>Travel roles govern permissions on Capital One Business Travel
    and are separate and distinct from cardholder roles (e.g., primary cardholder,
    Authorized User, and Account Manager). Travel roles and permissions are subject to change as we
    build out new features and capabilities, so please refer to the Capital One Business Travel
    Users tab for the latest and most accurate information.
  </li>
</ul>
<ul>
  <li>
    <strong>Admin role. </strong>Admins will be able to view Reports, All Trips, Users, Policies,
    and Out of Policy bookings. Admins will be able to make edits to Users and Policies, and review
    out-of-policy bookings. By default, the primary cardholder will be an Admin and cannot be
    removed as an Admin.
  </li>
  <li>
    <strong>Traveler role. </strong>Travelers will be able to book in-policy and out-of-policy
    travel, and digitally self-service their travel (e.g., change or cancel reservations) subject to
    the rules of each Supplier on their itinerary.
  </li>
</ul>
<h3><i>General Travel Information</i></h3>
<ul>
  <li>
    <strong>Required photo identification. </strong>Approved, government-issued photo identification
    is required for travel and must match the full name on the reservation. Due to the REAL ID
    requirements, your driver’s license or ID card may not be accepted for air travel after certain
    dates if it is not REAL ID. For the most current information on the REAL ID requirements, please
    visit: https://www.dhs.gov/real-id. Air carriers cannot board any passenger who fails to carry
    required documents. Some air carriers may also require you to show the credit card used as
    payment for your ticket(s).
  </li>
  <li>
    <strong>International travel requirements. </strong>If this is an international trip, you will
    need a passport, and you may need a visa. Travel to certain international destinations also may
    be subject to governmental health requirements. Passports usually need to be valid for a
    specified period of time (such as six months) after your scheduled return date. For foreign
    entry requirements, go to http://travel.state.gov or contact the embassy/consulate of the
    country to which you are traveling, including layover and stopover destinations, to determine
    entry documentation and other requirements, such as immunizations that you must satisfy,
    including return entry into the country from which you departed. It is solely your
    responsibility to obtain proper travel identification and satisfy all requirements for each
    location on your itinerary, including layover and stopover destinations. Capital One Business
    Travel is not responsible if you are refused entry onto a flight or into any country due to
    your failure to satisfy any entry requirements. Government imposed departure or entry taxes may
    not be included in ticket taxes. Passengers should be prepared to pay these taxes in cash, in
    local currency, on location. Requirements may change and you should check for up-to-date
    information before booking and departure.
  </li>
  <li>
    <strong>Minors. </strong>Minors under the age of 18 who are traveling with only one parent may
    be required to have additional documentation. Please contact your airline or the
    embassy/consulate of the country from which you are departing and the country to which you are
    traveling for additional information.
  </li>
  <li>
    <strong>Accommodations. </strong>Capital One Business Travel has no special knowledge
    regarding the suitability for persons with disabilities for any travel itinerary.
  </li>
  <li>
    <strong>Potential dangers. </strong>Capital One Business Travel has no special knowledge
    regarding unsafe conditions, health hazards, weather hazards, or climate extremes at locations
    to which you may travel. By offering reservations for travel products in particular
    destinations, Capital One Business Travel does not represent or warrant that travel to such
    areas is advisable or without risk, and is not liable for damages or losses that may result from
    travel to such destinations. For information concerning possible dangers at international
    destinations, Capital One Business Travel recommends contacting the Travel Advisory Section
    of the U.S. State Department at 202-647-5225 or checking online at
    travel.state.gov/traveladvisories.
  </li>
  <li>
    <strong>Public health. </strong>For travel-related public health information Capital One Business Travel
    recommends contacting the Centers for Disease Control and Prevention at
    800-232-4636. For foreign health requirements and dangers, go to http://www.cdc.gov/travel.
  </li>
  <li>
    <strong>Reservation rules. </strong>Travel reservations are subject to the rules of each
    Supplier on your itinerary. The information and descriptions given about the Suppliers are
    believed to be accurate, but Capital One Business Travel makes no warranty or representation
    regarding the information and descriptions.
  </li>
  <li>
    <strong>Tickets. </strong>The passenger's ticket(s), when issued, or the electronic reservation,
    shall constitute the sole contract between the Supplier and the purchaser and/or passenger.
    Capital One Business Travel shall have no liability for any actions or omissions of the
    Supplier, except where required by applicable law.
  </li>
  <li>
    <strong>Credits and vouchers. </strong>Capital One Business Travel shall have no
    responsibility for any credit or voucher issued by any Supplier, and any questions or issues you
    may have with respect to such credit or voucher must be addressed directly with the Supplier.
    Capital One Business Travel will only allow and facilitate usage of travel credits issued by
    airlines for bookings made through the Sites.
  </li>
  <li>
    <strong>Special requests and upgrades. </strong>Special requests made to a Supplier are on a
    request-only basis and cannot be guaranteed. Fees, taxes and charges may apply, depending on the
    service request. Upgrades are not permitted on certain itineraries. Please check with the
    Supplier directly. Supplier policies are subject to change at any time without notice.
  </li>
  <li>
    <strong>Loyalty programs. </strong>Certain rate types do not permit credit for airline frequent
    flyer programs or car or hotel loyalty programs. The name connected to the frequent flyer
    program or loyalty program must match the traveler listed on the itinerary or the Supplier may
    invalidate the booking.
  </li>
  <li>
    <strong>Luggage. </strong>Capital One Business Travel is not responsible for any lost,
    damaged, or delayed luggage.
  </li>
</ul>
<h3><i>Links to Other Internet Web Sites</i></h3>
<p>
  Some of the web sites listed as links herein are not under the control of Capital One Business Travel
   but rather are exclusively controlled by third parties. You should review the terms
  applicable to any third-party site that you choose to access. Accordingly, Capital One Business Travel
  makes no representations whatsoever concerning such websites. Although Capital One Business Travel
  may provide a link to a third party, such a link is not an authorization,
  endorsement, sponsorship or affiliation by Capital One Business Travel with respect to such
  Web site, its owners or its providers. Capital One Business Travel is providing these links
  only as a convenience to you. Capital One Business Travel has not tested any information,
  products or software found on such web sites and therefore cannot make any representations
  whatsoever with respect thereto. It is up to you to take precautions to ensure that whatever you
  select is free of such items as viruses, worms, trojan horses and other items of a destructive
  nature.
</p>
<p>
  IN NO EVENT WILL Capital One Business Travel, CAPITAL ONE, OR ITS AFFILIATED COMPANIES, BE
  LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE
  OF ANY HYPERLINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS
  INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE,
  EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
</p>
<h3><i>Third-Party Travel Services</i></h3>
<p>
  The purchase of third-party travel products and services through the Sites will be governed by and
  subject to additional terms and conditions from such providers, and subject to laws and
  regulations imposed by government entities, and you agree to abide by all such terms, laws, and
  regulations. Where possible, Capital One Business Travel will make these terms available to
  you. However, additional applicable terms may be provided on the third-party provider’s website,
  for example, an airline’s cancelation or refund policy. Please read these additional provider
  terms and conditions carefully prior to purchase, and ensure that you understand the full terms
  applicable to your airfare, hotel, trip insurance, car rental, or other third-party service. If
  you make bookings on behalf of another person(s) with their authorization, you agree that you will
  inform such other person(s) about these Terms and any third-party terms, rules and restrictions
  that apply to the bookings made on their behalf. You understand and agree that any violation of
  third-party terms of service may result in a cancelation of your bookings, denial of service, and
  forfeit of any amounts paid. Capital One Business Travel is not responsible for any damages
  resulting from your violation of third-party terms.
</p>
<h3><i>Flight Bookings Terms and Conditions</i></h3>
<ul>
  <li>
    <strong>General ticket rules. </strong>Airlines are also interchangeably referred to as “air
    carriers” or “carriers” in these Terms and Conditions. Airline tickets are NON-REFUNDABLE and
    NON-CHANGEABLE unless permitted by airline fare rules and, if permitted, are subject to airline
    rules, airline penalties up to the full amount of each ticket plus, in the case of a changed
    ticket, fare difference charges, and may also be subject to Capital One Business Travel
    fees. Credit card purchases of flights through the Sites, as well as any such fees, will appear
    on your monthly card statement as "COT*FLT*BIZ". <strong>Note:</strong> If there is more than
    one carrier on your itinerary, airline rules, airline penalties and change fees may apply to
    EACH airline on your itinerary and each change made.
  </li>
  <li>
    <strong>Unused or canceled tickets. </strong>If the ticket is refundable, there may be
    cancelation fees to refund the ticket. Unused tickets may have no value if not canceled prior to
    the scheduled departure date/time as specified by the airline's fare rules. Failure to use any
    reservations may result in automatic cancelation of all continuing and return reservations. You
    must advise your carrier if your travel plans change en route. Tickets cannot be reassigned or
    transferred to a different passenger or airline.
  </li>
  <li>
    <strong>Additional services and fees. </strong>Airlines may impose additional costs and fees for
    baggage, meals, beverages and other services. These costs are your sole responsibility. For
    information regarding airline liability limitations, baggage liability and other regulations of
    the Montreal Convention, and other regulations, please consult your air carrier.
  </li>
  <li>
    <strong>Flight availability. </strong>A small number of air carriers may require Capital One
    Business Travel to confirm flight availability when booking. If there is any issue with
    availability, you will receive an email notification of the unavailability within 72 hours and
    will be refunded to your original payment method for any charges. Also, please see U.S.
    NOTICE—OVERBOOKING OF FLIGHTS below regarding flight availability after booking.
  </li>
  <li>
    <strong>Airline travel credits</strong><strong>. </strong>In some situations an airline may
    issue you a credit “in lieu of” a refund according to the fare rules associated with the
    individual booking. If a credit is issued by an airline, it is held by the airline in the name
    of the individual who was the passenger of record on the original reservation. This credit can
    be used towards the payment for the booking of a new trip, subject to the terms and conditions
    of the credit-issuing airline.
  </li>
  <li>
    <strong>Flight schedules. </strong>Flight schedules are subject to change. Capital One Business
    Travel may inform you of schedule changes in some scenarios, but is not responsible for
    any schedule change(s) or notifying you of such change(s). It is your responsibility to confirm
    the scheduled departure time(s) on the airlines' websites at least 48 hours prior to departure
    for domestic flights and at least 72 hours prior to departure for international flights to learn
    if your flight schedule changed.
  </li>
  <li>
    <strong>Check-in time and requirements. </strong>Check with each airline regarding its specific
    boarding and check-in requirements. We recommend that you check-in before arriving at the
    airport, where such advance check-in is offered by our airline. Some airlines offer advance
    check-in beginning 24 hours before departure. CHECK-IN TIME: The time shown on the
    itinerary/receipt is the departure time of the aircraft. Flight departure time is not the same
    as the time you must check-in or the time you must be available for boarding. Your carrier may
    refuse your carriage if you arrive after the aircraft has completed boarding or you are
    otherwise late. Check-in times, as advised by your carrier, are the latest times at which
    passengers can be accepted for travel; boarding times, as advised by your carrier, are the
    latest times at which passengers must present themselves for boarding. Flight cannot be held for
    late-arriving passengers, and Capital One Business Travel accepts no responsibility in such
    cases.
  </li>
  <li>
    <strong>Aircraft. </strong>Turboprop aircraft may exist on your itinerary. Airlines reserve the
    right to change aircraft equipment without notice to the booking travel agency or the consumer.
  </li>
  <li>
    <strong>Code-sharing. </strong>If a code-share flight exists in your itinerary (a flight where
    two or more airlines share the same flight), passengers must check in with the operating airline
    on the day of departure.
  </li>
  <li>
    <strong>Tickets and boarding passes. </strong>All tickets will be issued at the time of booking
    as e-tickets. You will receive a boarding pass upon checking in with the airline digitally or at
    the airport.
  </li>
  <li>
    <strong>Seat selection. </strong>Advance seat assignments, if available and allowed by airline,
    are not guaranteed and could be subject to additional fees. Please inquire with the airline.
  </li>
  <li>
    <strong>Insecticides. </strong>Please go to
    <a href="https://www.dot.gov/office-policy/aviation-policy/aircraft-disinsection-requirements"
      >https://www.dot.gov/office-policy/aviation-policy/aircraft-disinsection-requirements</a
    >
    to learn about the use of insecticides in certain aircraft.
  </li>
  <li>
    <strong>Large group bookings</strong><strong>.</strong> You may add up to 6 passengers onto a
    single booking. If you need to book for more than 6 passengers, you may do so for any remaining
    passengers via another booking. Each booking will have a maximum of 6 passengers. Please ensure
    that you do not duplicate passenger names across bookings with identical flights and dates. If
    multiple bookings are identical, including passenger names, they are considered duplicate
    bookings and will be subject to cancelation by the airline.
  </li>
  <li>
    <strong>Unaccompanied minors policy. </strong>Unfortunately, due to airline contracts, Capital
    One Business Travel cannot support online bookings in which the only traveler is less than
    18 years old. If you want to book a flight for an unaccompanied minor, please call Capital One
    Business Travel and book via phone (844-422-6922). The phone booking fee for an
    unaccompanied minor booking will be waived.
  </li>
  <li>
    <strong>Canadian traveler rights.</strong> For flights that are destined for, transit through,
    or depart Canada, passengers may be entitled to certain standards of treatment and compensation
    under the Air Passenger Protection Regulations. For more information about your passenger rights
    please contact your air carrier or visit the Canadian Transportation Agency’s website at
    <a href="https://www.otc-cta.gc.ca/">https://www.otc-cta.gc.ca/</a>.
  </li>
  <li>
    <strong>EU traveler rights.</strong> If you are traveling into or out of the European Union, or
    on an EU-based airline, You may have rights in the event that your flight is canceled, delayed
    or you are denied boarding. Please consult Regulation (EC) 261/2004 for more information.
  </li>
  <li>
    <strong>Hazardous materials. </strong>Federal law forbids the carriage of hazardous materials
    aboard aircraft in your luggage or on your person. A violation can result in five years'
    imprisonment and penalties of $250,000 or more (49 U.S.C. 5124). Hazardous materials include
    explosives, compressed gasses, flammable liquids and solids, oxidizers, poisons, corrosives and
    radioactive materials. Examples: Paints, lighter fluid, fireworks, tear gases, oxygen bottles,
    and radio-pharmaceuticals. There are special exceptions for small quantities (up to 70 ounces
    total) of medicinal and toilet articles carried in your luggage, spare lithium batteries in
    carry-on baggage, and certain smoking materials carried on your person. Certain items are
    required to be carried with you onboard the aircraft. Spare lithium batteries and cigarette
    lighters must be removed from checked or gate-checked baggage and carried onboard the aircraft.
  </li>
  <li>
    <strong>Baggage allowance.</strong> Excess valuation may be declared on certain types of
    articles. Carriers may apply special rules for fragile, valuable, or perishable articles. Check
    with your carrier. Carriers may permit a free checked baggage allowance, which is set by the
    carrier and may differ by class, and/or route. Carriers may apply extra charges for checked
    baggage in excess of their permitted allowance. Check with your carrier. Cabin (Unchecked)
    Baggage: Carriers may permit a free cabin baggage allowance, which is set by the carrier and may
    differ by class, route, and/or aircraft type. It is recommended that cabin baggage be kept to a
    minimum. Check with your carrier. If more than one carrier is providing the transportation for
    your journey, each carrier may apply different rules on baggage (both checked and cabin).
    SPECIAL BAGGAGE LIABILITY LIMITATIONS FOR U.S. TRAVEL: For domestic travel wholly between U.S.
    points, federal rules require any limit on a carrier’s baggage liability to be at least US
    $3,800 per passenger, or the amount currently mandated by 14 CFR § 254.5.
  </li>
  <li>
    <strong>Required information. </strong>The Transportation Security Administration (TSA), part of
    the U.S. Department of Homeland Security, requires Capital One Business Travel to collect
    information from you for purposes of watch list screening, pursuant to 49 U.S.C. Sec. 114, and
    the Intelligence Reform and Terrorism Prevention Act of 2004. TSA may share information you
    provide with law enforcement or intelligence agencies or others under its published system of
    records notice. For more information, please see <a href="https://www.tsa.gov">www.tsa.gov</a>.
  </li>
</ul>
<h4>ADVICE TO INTERNATIONAL PASSENGERS ON LIMITATIONS OF LIABILITY</h4>
<p>
  PASSENGERS EMBARKING UPON A JOURNEY INVOLVING AN ULTIMATE DESTINATION OR A STOP IN A COUNTRY OTHER
  THAN THE COUNTRY OF DEPARTURE ARE ADVISED THAT THE PROVISIONS OF AN INTERNATIONAL TREATY (THE
  WARSAW CONVENTION, THE 1999 MONTREAL CONVENTION, OR OTHER TREATY), AS WELL AS A CARRIER’S OWN
  CONTRACT OF CARRIAGE OR TARIFF PROVISIONS, MAY BE APPLICABLE TO THEIR ENTIRE JOURNEY, INCLUDING
  ANY PORTION ENTIRELY WITHIN THE COUNTRIES OF DEPARTURE AND DESTINATION. THE APPLICABLE TREATY
  GOVERNS AND MAY LIMIT THE LIABILITY OF CARRIERS TO PASSENGERS FOR DEATH OR PERSONAL INJURY,
  DESTRUCTION OR LOSS OF, OR DAMAGE TO, BAGGAGE, AND FOR DELAY OF PASSENGERS AND BAGGAGE.
</p>
<p>
  ADDITIONAL PROTECTION CAN USUALLY BE OBTAINED BY PURCHASING INSURANCE FROM A PRIVATE COMPANY. SUCH
  INSURANCE IS NOT AFFECTED BY ANY LIMITATION OF THE CARRIER’S LIABILITY UNDER AN INTERNATIONAL
  TREATY. FOR FURTHER INFORMATION PLEASE CONSULT YOUR AIRLINE OR INSURANCE COMPANY REPRESENTATIVE.
</p>
<p>Notice of Contract Terms Incorporated by Reference</p>
<p>
  Your contract of carriage with the carrier that provides you with carriage by air, whether
  international, domestic or a domestic portion of an international journey is subject to this
  notice; to any notice or receipt of the carrier; and to the carrier’s individual terms and
  conditions (Conditions), related rules, regulations and policies (Regulations) and any applicable
  tariffs If your carriage is by more than one carrier, different Conditions, Regulations and any
  applicable tariffs may apply for each carrier. The Conditions, Regulations and any applicable
  tariffs of each carrier are, by this notice, incorporated by reference into and made part of your
  contract of carriage. The Conditions may include, but are not restricted to:
</p>
<p>
  (a) Conditions and limits on the carrier’s liability for the bodily injury or death of passengers;
  (b) Conditions and limits on the carrier’s liability for the loss of, damage to or delay of goods
  and baggage, including fragile or perishable goods; (c) rules for declaring a higher value for
  baggage and for paying any supplementary fee that may apply; (d) application of the carrier’s
  Conditions and limits of liability to the acts of the carrier’s agents, servants and
  representatives, including any person providing either equipment or services to the carrier; (e)
  claims restrictions, including time limits by which passengers must file claims or bring actions
  against the carrier; (f) rules about reconfirmations or reservations; check in times; the use,
  duration and validity of air transportation services; and the carrier’s right to refuse carriage;
  (g) rights of the carrier and limits on the carrier’s liability for delay or failure to perform a
  service, including schedule changes, substitution of alternative carriers or aircraft and
  re-routing, and, when required by applicable law, the obligation of the carrier to notify
  passengers of the identity of the operating carrier or substituted aircraft; and (h) rights of the
  carrier to refuse carriage to passengers who fail to comply with applicable laws or who fail to
  present all necessary travel documents.
</p>
<p>
  You can obtain additional information on items (a) through (h) above and about your contract of
  carriage (including how to request a copy thereof) at places where transportation on the carrier
  is sold. Many carriers also have this information on their websites. When required by applicable
  law, you have the right to inspect the full text of your contract of carriage at the carrier’s
  airport and sales offices, and upon request, to receive a copy by mail or other delivery service
  from each carrier free of charge. If a carrier sells air transportation services or checks baggage
  specifying carriage on another carrier, it does so only as an agent for the other carrier.
</p>
<p>
  Itinerary Changes/Cancelations. Itinerary changes or cancelations you request (i.e., voluntary
  refunds), if permitted under the airline fare rules applicable to your ticket, may be subject to
  airline-imposed fees in addition to any fare differential at re-ticketing. Such fees vary by
  airline. Your airline may permit a portion of the fare paid for an unused nonrefundable ticket to
  be applied toward the purchase of future travel. For many airlines, tickets not changed or
  canceled prior to departure have no remaining value.
</p>
<p>
  Tax Refunds. In case of a no-show or cancelation of an air booking, you may be entitled to a
  refund of government taxes and fees included in the price of the ticket purchased. Where you are
  eligible for a refund, you can request such a refund from Capital One Business Travel, who
  will submit your request to the airline on your behalf.
</p>
<p>
  Airline Prohibited Practices. The circumvention of an air carrier’s rules, including practices
  such as back-to-back ticketing (booking two or more tickets with overlapping travel dates in order
  to circumvent minimum stay requirements) and hidden-city, point beyond or throwaway ticketing
  (booking tickets that include segments you do not intend to use in order to circumvent an air
  carrier’s pricing structure), is prohibited by many air carriers. You agree that you will not use
  Capital One Business Travel to engage in these or similar activities if prohibited by an
  airline.
</p>
<p>
  CARRIERS RESERVE THE RIGHT TO REFUSE CARRIAGE TO ANY PERSON WHO HAS ACQUIRED A TICKET IN VIOLATION
  OF APPLICABLE LAW OR THE CARRIER’S TARIFFS, RULES OR REGULATIONS.
</p>
<p>
  YOU CANNOT TRAVEL IF YOU DO NOT HAVE ALL REQUIRED TRAVEL DOCUMENTS, SUCH AS PASSPORT AND VISA.
  PASSENGER SHALL ARRIVE AT AIRPORT BY TIME EARLY ENOUGH TO COMPLETE DEPARTURE PROCEDURES.
</p>
<p>
  GOVERNMENTS MAY REQUIRE YOUR CARRIER TO PROVIDE INFORMATION ON OR PERMIT ACCESS TO PASSENGER DATA,
  INCLUDING INFORMATION WE MAY BE REQUIRED TO COLLECT FROM YOU DURING THE RESERVATION PROCESS.
</p>
<p>
  U.S. NOTICE—OVERBOOKING OF FLIGHTS. Airline flights may be overbooked, and there is a slight
  chance that a seat will not be available on a flight for which a person has a confirmed
  reservation. If the flight is overbooked, no one will be denied a seat until airline personnel
  first ask for volunteers willing to give up their reservation in exchange for a payment of the
  airline’s choosing. If there are not enough volunteers the airline will deny boarding to other
  persons in accordance with its particular boarding priority. With few exceptions persons denied
  boarding involuntarily are entitled to compensation. The complete rules for the payment of
  compensation and each airline’s boarding priorities are available at all airport ticket counters
  and boarding locations. Some airlines do not apply these consumer protections to travel from some
  foreign countries although other consumer protections may be available. Check with the airline or
  your travel agent.
</p>
<h3><i>Hotel/Lodging Bookings Terms and Conditions</i></h3>
<ul>
  <li>
    <strong>General hotel booking rules. </strong>Hotel rooms are NON-REFUNDABLE and NON-CHANGEABLE
    unless permitted by the terms of the room/rate description. Check the terms carefully before
    making a hotel reservation for cancelation or change terms and fees. For rooms that are
    non-refundable, if you change or cancel your reservation at any time, you will still be charged
    the full reservation amount of room and tax for the entire stay. Early check-out from a hotel is
    not subject to a refund, unless allowed by the Supplier. If permitted, cancelations or
    modifications received at any time may be subject to a Capital One Business Travel fee in
    addition to any Supplier fees. Credit card purchases of hotel rooms through the Sites, as well
    as any such fees, will appear on your monthly card statement as "COT*HTL*BIZ". Please note that
    reservations do not include services not specified in the reservation confirmation.
  </li>
  <li>
    <strong>Cancelations. </strong>Contact Capital One Business Travel via the number listed on
    your itinerary (844-422-6922) for all cancelation or change requests. Cancelations or changes
    handled by the hotel directly may result in additional fees and/or the forfeiture of any refund
    due. When canceling hotel reservations, retain your cancelation number so you will not be held
    responsible for cancelation charges if you cancel within the permitted cancelation window and
    the hotel makes an error.
  </li>
  <li>
    <strong>Non-transferability. </strong>Hotel room reservations cannot be transferred or
    reassigned to another traveler and cannot be resold. If we determine that you have transferred
    or re-sold a reservation, those reservations are subject to cancelation and will incur
    applicable cancelation fees in accordance with the rate description.
  </li>
  <li>
    <strong>No shows and lateness. </strong>No shows are non-refundable and will result in a
    forfeiture of all payments made and used by you in connection with the reservation, without
    credit due. If you think you may arrive at a hotel late, please contact the hotel directly to
    arrange for late arrival, if available. Actual times for "late" vary by hotel.
  </li>
  <li>
    <strong>Check-in requirements. </strong>The hotel will require a major credit card, in the name
    of one of the guests, or a cash deposit upon check-in. Government-issued photo identification is
    required at check-in and must match the name on the reservation. Some properties have a minimum
    age requirement for check-in. In some cases, your name may not be provided by Capital One Business
    Travel to the hotel until 24 hours prior to your arrival, due to hotel Supplier policies
    applicable to our preferred rates.
  </li>
  <li>
    <strong>Incidental charges. </strong>Hotel reservations include room and applicable hotel taxes
    only. Any additional hotel charges, such as resort fees and hotel energy surcharges and cleaning
    fees, and any charges for incidentals that you incur are not included in your reservation rate
    and must be paid directly to the hotel. Incidental charges may include but are not limited to
    parking fees, baby sitting, room service, telephone fees, internet usage fees, in-room movies,
    mini-bar charges, and gratuities.
  </li>
  <li>
    <strong>Special requests. </strong>Policies for children vary by hotel. Please contact the hotel
    directly to learn whether child benefits are offered and whether there are child restrictions.
    Additionally, please contact the hotel directly for any special requests, such as bed type,
    smoking preferences or in-room amenities. Special requests are subject to hotel availability.
  </li>
  <li>
    <strong>Potential renovations and refurbishments. </strong>A reasonable attempt will be made to
    notify guests of hotel renovation or refurbishment if Capital One Business Travel knows of
    the same; however, Capital One Business Travel shall not be liable for any failure to
    provide such notification or for damages that may result from renovation or refurbishment.
  </li>
  <li>
    <strong>Hotel ratings. </strong>Third-party hotel ratings displayed on the Sites are intended as
    only general guidelines, and Capital One Business Travel does not guarantee or endorse any
    hotel, or the accuracy of any ratings.
  </li>
  <li>
    <strong>Loyalty rates and hotel status. </strong>Capital One Business Travel may have hotel
    inventory shown on the Sites that have specially negotiated loyalty rates and discounts. Capital
    One Business Travel has no obligation or responsibility to provide or continue to provide
    loyalty rates and discounts. Any such loyalty rates and discounts are for the benefit of
    cardholders. In order to earn points towards status with a hotel chain, you must input the
    loyalty number associated with the hotel chain at the time of booking. Your name must match the
    name on the loyalty account for hotels to validate.
  </li>
</ul>
<h3><i>Rental Car Bookings Terms and Conditions</i></h3>
<ul>
  <li>
    <strong>General rental car booking rules. </strong>Car rental redemptions may not be available
    for all locations and destinations. Cancelations or modifications received at any time are
    subject to the Supplier’s cancelation policies and cancelation fees which could be up to the
    full amount of the reservation and may also be subject to a Capital One Business Travel fee.
    Credit card purchases of rental cars through the Sites, as well as any such fees, will appear on
    your monthly card statement as COT*CAR*BIZ.
  </li>
  <li>
    <strong>No shows. </strong>No shows are non-refundable and will result in a total forfeiture of
    any payments made and used by you in connection with the reservation, without credit due.
  </li>
  <li>
    <strong>Early returns. </strong>The early return of car rentals is not eligible for a refund,
    regardless of situation or notification to the car rental company.
  </li>
  <li>
    <strong>Additional fees and surcharges. </strong>Any additional taxes, fees and surcharges are
    subject to change without notice, may vary by location, and may be charged to the customer at
    pick-up.
  </li>
  <li>
    <strong>Rental rates and rental periods. </strong>Rental rates are based on 24-hour periods and
    may be subject to additional fees depending on time of return, including but not limited to
    hourly rental charges, which will be billed directly to you by the car rental company. Certain
    car rentals may have a minimum rental period. Any rentals less than the required minimum days
    may be charged the minimum rental period.
  </li>
  <li>
    <strong>Domestic and international rates and considerations. </strong>Advance purchase car
    rental rates in the United States include unlimited mileage, taxes and fees. Car rental rates
    outside of the United States may not include unlimited mileage, taxes and fees and these will be
    assessed by the car rental company directly. Charges are billed directly by the car rental
    company and rates are subject to change. Rentals outside the country in which you reside may
    require an international driver’s license or compliance with other local requirements.
    Geographic and cross border restrictions may apply. Local renters and renters driving out of
    state/country may be subject to additional restrictions.
  </li>
  <li>
    <strong>Additional charges. </strong>Charges for optional services such as insurance, fuel,
    additional or underage drivers, and special equipment charges, are not included in your rental
    and must be paid directly to the car rental company. Car rental rates do not include Collision
    Damage Waiver insurance and Theft Protection unless specified. Please contact your insurance
    company if you are unsure whether to accept rental car company insurance at the counter.
  </li>
  <li>
    <strong>Vehicle. </strong>Capital One Business Travel does not guarantee a specific make,
    model, or color of vehicle no matter what vehicle is reserved.
  </li>
  <li>
    <strong>Age requirements. </strong>Renters must meet the minimum (and maximum, if applicable)
    age requirement where the car is being rented, have a valid driver's license, major credit card
    and some Suppliers require a good driving record. Most rental car companies do not accept debit
    cards. Some Suppliers charge a surcharge for drivers above or below certain ages, which is
    collected by the Supplier at the time of pickup. Suppliers reserve the right to deny car rentals
    for any reason, including past driving records.
  </li>
  <li>
    <strong>One way rentals. </strong>One way rentals may or may not be permitted. If there are
    additional fees for one way rentals, when permitted, these fees will not be included in the cost
    of the reservation. A reasonable attempt to advise of these fees will be made when this
    information is available to Capital One Business Travel.
  </li>
</ul>
<h2><u>Terms for Additional Products and Features</u></h2>
<p>
  If offered, the additional products and features made available through Capital One Business Travel
  are subject to the following Terms and Conditions. Availability of these services may
  vary based on your account, and not all services are available to all customers or on all
  bookings. The availability of the services is subject to change without prior notice. Additional
  fees may apply. Capital One Business Travel may revise these Terms and Conditions at any time
  by updating this posting.
</p>
<p>
  Some of the services made available through Capital One Business Travel are offered by Hopper
  (USA) Inc. and its affiliated companies (“Hopper”). Your use of the services offered by Hopper,
  and other third-parties that may be linked to from the Sites will be governed by their respective
  terms and conditions and privacy policies.
</p>
<h3><i>Price Prediction and Alerts</i></h3>
<p>
  Capital One Business Travel’s price prediction services and notifications, offered in
  collaboration with Hopper, provide a powerful tool to assist you in finding the best time to book
  travel and save money. Capital One Business Travel and Hopper work hard to ensure the accuracy
  of its predictions based on past data and current trends. However, forecasting price movements in
  a highly competitive, unpredictable global market has inherent limitations, and no prediction tool
  can be 100% accurate. Accordingly, while Capital One Business Travel and Hopper believe
  strongly in the accuracy of its predictions, they do not guarantee its accuracy, and shall not be
  liable for any reliance upon them, except as expressly set forth in the terms of any Capital One
  Business Travel product purchased by you, such as a frozen price.
</p>
<p>
  Disclaimer: Availability of these services may vary based on your account, and not all services
  are available to all customers or on all bookings. The availability of the services is subject to
  change without prior notice. Capital One Business Travel may revise these Terms and Conditions
  at any time by updating this posting.
</p>
<p>
  Some of the services made available through Capital One Business Travel are offered by Hopper
  (USA) Inc. and its affiliated companies (“Hopper”). Your use of the services offered by Hopper,
  and other third-parties that may be linked to from the Sites will be governed by their respective
  Terms and Conditions and Privacy Policies.
</p>

<h3><i>Cancel for Any Reason (Flights)</i></h3>
${FLIGHT_CANCEL_FOR_ANY_REASON_BODY}

${
  isHotelCfarEnabled
    ? `<h3><i>${HOTEL_CFAR_TITLE}</i></h3>
    ${HOTEL_CFAR_BODY}
    ${HOTEL_CFAR_TABLE}`
    : ""
}

<h3><i>Flight Disruption Assistance (formerly known as Rapid Rebooking)</i></h3>
${CORP_DISRUPTION_PROTECTION_BODY}

<h3><i>Price Match Guarantee</i></h3>
<p>
  Most flight, hotel, and rental car purchases made through Capital One Business Travel are
  eligible for price matches if you find a cheaper itinerary elsewhere and let us know within 24
  hours of booking through Capital One Business Travel. Availability of this service may vary,
  and not all services are available to all customers or on all bookings. Capital One Business Travel
  may revise these Terms and Conditions at any time by updating this posting.
</p>
<ol>
  <li>
    <strong>Qualification criteria.</strong> To qualify for Capital One Business Travel’s price
    match guarantee, you must keep your original itinerary and the cheaper itinerary found must be:
    <ol type="a">
      <li>
        An exact match to the original itinerary that was made within the last 24 hours (including
        the cancellation policy, i.e. refundable or non-refundable);
      </li>
      <li>Priced in USD, including any taxes and fees;</li>
      <li>
        Available to the general public. For example, this means that the rate doesn’t require the
        customer to log into a site or have a special membership;
      </li>
      <li>
        Available at the time the customer contacts us. Our agent must be able to verify that the
        lower price exists.
      </li>
    </ol>
  </li>
  <li>
    <p>
      <strong>Submitting a claim.</strong> You must submit a price match guarantee claim through an
      agent by calling Capital One Business Travel at 833-233-5660. Please provide the trip ID
      for the original booking, the amount of the lower price, and the website where you found the
      lower price so that our agents can verify the lower price. If the claim is verifiable, our
      agents will authorize the refund live on the phone.
    </p>
    <p>
      If our agents are unable to verify the lower price, we will verify offline and will attempt to
      respond within 24 hours, though it may take longer in certain cases. If the claim is
      verifiable, you will receive an email confirmation of your refund. If the claim is not
      verifiable after an offline review, you will be notified of the decline by email, and you may
      not receive a refund.
    </p>
  </li>
  <li>
    <p>
      <strong>Refund method and amount.</strong> Refund method and amount. For reservations that
      meet all price match guarantee criteria, we will refund you 100% of the difference in price to
      your original payment method (e.g., credit card, travel credits account and/or rewards
      redemption). If you used a credit card and redeemed rewards to purchase the fare, the
      difference will be refunded proportionally, up to the benefit maximum.
    </p>
  </li>
  <li>
    <p>
      <strong>Refund timing and rewards adjustments.</strong> Refunds should appear on your account
      within 1-2 billing cycles. Please be aware that the portion of the transaction that is
      refunded will result in an adjustment of any rewards earned on that transaction.
    </p>
  </li>
</ol>

 <h3><i>Travel Credits</i></h3>
 ${CORP_TRAVEL_CREDITS_BODY}

 <h2>Website terms of use</h2>
 ${WEBSITE_TERMS_OF_USE_BODY}
`;

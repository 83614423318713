export const PREMIER_COLLECTION_TITLE = "Premium Stays";
export const PREMIER_COLLECTION_SUBTITLE = "Terms and Conditions";
export const PREMIER_COLLECTION_BODY = `<p><strong>Eligibility.</strong> To qualify for the Guaranteed Benefits and the Benefits Subject to Availability described below, Capital One Travel Premier Collection bookings must be made through Capital One Travel’s booking site, in the Venture X or Venture X Business cardholder’s name, and that cardholder must be a member of the party traveling under the booking. For hotels, benefits are applied to each room booked under an eligible stay; a four-room limit per stay applies. Back-to-back bookings at the same property (less than 24-hours separating reservations) are considered one stay. Hotel terms and conditions apply and a minimum number of nights may be required by the hotel for booking. Participating properties and benefits are subject to change. Offer valid for consumer, non-commercial use only, unless otherwise permitted. We reserve the right to disqualify any cardholder from participation in this offer in the event of fraud, abuse, or a violation of the terms as determined at our discretion. Such termination may result in the forfeiture of any benefits. </p>

<p>For vacation rentals, Premier Collection booking must be made through Capital One Travel in the eligible cardholder’s name, with the cardholder as a member of party traveling, to qualify for benefits. Benefits applied per stay. Back-to-back bookings of the same property within a 24-hour period will be considered one reservation and are not eligible for double benefits. Host provider terms and conditions apply; a minimum number of nights may be required by the host for booking. Benefits cannot be redeemed for cash or combined with other offers unless specified. Participating properties and benefits are subject to change. Offer not intended for commercial use unless otherwise permitted. We may disqualify any cardholder from participation and benefits at our sole discretion. </p>

<p><strong>Guaranteed Benefits.</strong> Eligible cardholders will have the following benefits available to them for Premier Collection hotel bookings: (1) complimentary daily breakfast for two people, (2) complimentary Wi-Fi access, (3) 10 miles per dollar spent for net purchases (purchases minus any credits or returns) of Premier Collection bookings made through Capital One Travel’s booking site, and (4) a hotel experience credit of $100. Purchases made outside of Capital One Travel’s booking site, including but not limited to hotel incidentals, upgrades, or other expenses, will not earn the elevated rewards, but will receive the standard purchase earn rate. The hotel experience credit will be applied to qualifying charges at checkout by the hotel for up to $100 USD or the local currency equivalent. Qualifying charges vary by location and must be charged or applied to your room during your eligible stay (i.e., expenses or incidentals paid for directly with a card or other payment method at the time of service or purchase, rather than applied to your room, will not be eligible for the experience credit at checkout). Contact the hotel directly or inquire at check-in to confirm qualifying experiences and expenses. Advance reservations for certain qualifying experiences (e.g., spa) are recommended. Amenities vary by property. Benefits cannot be redeemed for cash, must be used during the eligible stay booked, and may not be combined with other offers unless specified. </p>

<p>For Premier Collection vacation rental bookings: (1) 5 miles per dollar spent for net purchases (purchases minus any credits or returns) of Premier Collection bookings made through Capital One Travel’s booking site, (2) a vacation rental experience credit of $100. Purchases made outside of Capital One Travel’s booking site will not earn elevated rewards, but will receive the standard purchase earn rate. The Premier Collection vacation rental experience credit will be applied at time of booking the experience by the host provider to qualifying experience charges up to $100 USD or the local currency equivalent. Experiences must be booked with a Capital One credit card. Qualifying experience credit options vary by location and are subject to availability. Contact the host provider directly to confirm qualifying experiences - advance reservations recommended for certain experiences. For certain properties, the experience credit will be provided in the form of a gift card. </p>

<p><strong>Benefits Subject to Availability.</strong> Eligible cardholders may have the following benefits available to them for Premier Collection hotel bookings if they are available: (1) early check-in, (2) late check-out, and (3) room upgrades. Certain room categories may not be eligible for upgrade. Eligible cardholders may have early check-in and late check-out available to them for Premier Collection vacation rental bookings if they are available. All other benefits not specifically listed are subject to availability. Benefits cannot be redeemed for cash, must be used during the eligible stay booked, and may not be combined with other offers unless specified. </p>`;

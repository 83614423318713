import React from "react";
import Box from "@material-ui/core/Box";
import { Switch, Route } from "react-router-dom";

import {
  PATH_PRICE_PREDICTION_PRICE_WATCH,
  PATH_PRICE_DROP_PROTECTION,
  PATH_CANCEL_FOR_ANY_REASON,
  PATH_FROZEN_PRICES,
  PATH_PRICE_MATCH_GUARANTEE,
  PATH_SITE_TERMS_OF_USE,
  PATH_TERMS_OF_SERVICE,
  PATH_HOME,
  PATH_DISRUPTION_PROTECTION,
  PATH_TRAVEL_CREDITS,
  PATH_HOTEL_CANCEL_FOR_ANY_REASON,
} from "../../../utils/paths";

import { ContentBlock } from "halifax";
import { getConfigTenant, PATH_CORP_FAQ } from "@capone/common";

import {
  PRICE_MATCH_GUARANTEE_TITLE,
  PRICE_MATCH_GUARANTEE_SUBTITLE,
} from "./TextConstants/bestPriceGuaranteeTextConstant";

import {
  CANCEL_FOR_ANY_REASON_TITLE,
  CANCEL_FOR_ANY_REASON_SUBTITLE,
} from "./TextConstants/cancelForAnyReasonTextConstant";

import {
  FROZEN_PRICES_TITLE,
  FROZEN_PRICES_SUBTITLE,
} from "./TextConstants/frozenPricesTextConstant";

import {
  PRICE_DROP_PROTECTION_TITLE,
  PRICE_DROP_PROTECTION_SUBTITLE,
} from "./TextConstants/priceDropProtectionTextConstant";

import {
  PRICE_DROP_PROTECTION_BODY,
  PRICE_PREDICTION_PRICE_WATCH_BODY,
  PRICE_MATCH_GUARANTEE_BODY,
  FROZEN_PRICES_BODY,
  FLIGHT_CANCEL_FOR_ANY_REASON_BODY,
  CORP_DISRUPTION_PROTECTION_BODY,
  HOTEL_CFAR_TITLE,
  HOTEL_CFAR_SUBTITLE,
  HOTEL_CFAR_BODY,
  HOTEL_CFAR_TABLE,
} from "redmond";

import {
  TERMS_OF_SERVICE_TITLE,
  TERMS_OF_SERVICE_SUBTITLE,
  TERMS_OF_SERVICE_BODY,
} from "./TextConstants/termsOfServiceTextConstant";
import {
  SITE_TERMS_OF_USE_TITLE,
  SITE_TERMS_OF_USE_SUBTITLE,
  SITE_TERMS_OF_USE_BODY,
} from "./TextConstants/siteTermOfUseTextConstant";

import {
  PRICE_PREDICTION_PRICE_WATCH_TITLE,
  PRICE_PREDICTION_PRICE_WATCH_SUBTITLE,
} from "./TextConstants/pricePredictionPriceWatchTextConstants";

import FAQ from "../../../pages/capone-corporate/FAQ";
import {
  DISRUPTION_PROTECTION_SUBTITLE,
  DISRUPTION_PROTECTION_TITLE,
} from "./TextConstants/disruptionProtectionTextConstant";
import {
  TRAVEL_CREDITS_TITLE,
  TRAVEL_CREDITS_SUBTITLE,
} from "../capone/TextConstants/travelCreditsTextContant";
import { useExperimentIsVariant } from "@capone/experiments";
import {
  AVAILABLE,
  HOTELS_CFAR,
  TRAVEL_WALLET_CREDIT_EXPERIMENT,
} from "../../../context/experiments";
import { CORP_TRAVEL_CREDITS_BODY } from "./TextConstants/travelCreditsTextConstant";

interface IBodyProps {
  language: string;
}

const Body = (_props: IBodyProps) => {
  const isHotelsCfarEnabled = useExperimentIsVariant(HOTELS_CFAR, AVAILABLE);

  const getBodyContent = (path: string) => {
    switch (path) {
      case PATH_PRICE_PREDICTION_PRICE_WATCH:
        return [
          PRICE_PREDICTION_PRICE_WATCH_TITLE,
          PRICE_PREDICTION_PRICE_WATCH_SUBTITLE,
          PRICE_PREDICTION_PRICE_WATCH_BODY(getConfigTenant()),
        ];
      case PATH_PRICE_DROP_PROTECTION:
        return [
          PRICE_DROP_PROTECTION_TITLE,
          PRICE_DROP_PROTECTION_SUBTITLE,
          PRICE_DROP_PROTECTION_BODY,
        ];
      case PATH_FROZEN_PRICES:
        return [
          FROZEN_PRICES_TITLE,
          FROZEN_PRICES_SUBTITLE,
          FROZEN_PRICES_BODY,
        ];
      case PATH_PRICE_MATCH_GUARANTEE:
        return [
          PRICE_MATCH_GUARANTEE_TITLE,
          PRICE_MATCH_GUARANTEE_SUBTITLE,
          PRICE_MATCH_GUARANTEE_BODY,
        ];
      case PATH_CANCEL_FOR_ANY_REASON:
        return [
          CANCEL_FOR_ANY_REASON_TITLE,
          CANCEL_FOR_ANY_REASON_SUBTITLE,
          FLIGHT_CANCEL_FOR_ANY_REASON_BODY,
        ];
      case PATH_HOTEL_CANCEL_FOR_ANY_REASON:
        return [
          HOTEL_CFAR_TITLE,
          HOTEL_CFAR_SUBTITLE,
          HOTEL_CFAR_BODY + HOTEL_CFAR_TABLE,
        ];
      case PATH_DISRUPTION_PROTECTION:
        return [
          DISRUPTION_PROTECTION_TITLE,
          DISRUPTION_PROTECTION_SUBTITLE,
          CORP_DISRUPTION_PROTECTION_BODY,
        ];
      case PATH_SITE_TERMS_OF_USE:
        return [
          SITE_TERMS_OF_USE_TITLE,
          SITE_TERMS_OF_USE_SUBTITLE,
          SITE_TERMS_OF_USE_BODY,
        ];
      case PATH_TRAVEL_CREDITS:
        return [
          TRAVEL_CREDITS_TITLE,
          TRAVEL_CREDITS_SUBTITLE,
          CORP_TRAVEL_CREDITS_BODY,
        ];
      case PATH_TERMS_OF_SERVICE:
      default:
        return [
          TERMS_OF_SERVICE_TITLE,
          TERMS_OF_SERVICE_SUBTITLE,
          TERMS_OF_SERVICE_BODY(isHotelsCfarEnabled),
        ];
    }
  };

  const renderTerms = (path: string) => {
    const [title, subtitle, body] = getBodyContent(path);
    return <ContentBlock {...{ title, subtitle, body }} />;
  };

  const isTravelWalletCreditsExperiment = useExperimentIsVariant(
    TRAVEL_WALLET_CREDIT_EXPERIMENT,
    AVAILABLE
  );

  return (
    <Box className="terms-body-container corporate">
      <Switch>
        <Route
          path={[
            PATH_PRICE_PREDICTION_PRICE_WATCH,
            PATH_PRICE_DROP_PROTECTION,
            PATH_CANCEL_FOR_ANY_REASON,
            PATH_HOTEL_CANCEL_FOR_ANY_REASON,
            PATH_FROZEN_PRICES,
            PATH_PRICE_MATCH_GUARANTEE,
            PATH_SITE_TERMS_OF_USE,
            PATH_TERMS_OF_SERVICE,
            PATH_HOME,
            PATH_DISRUPTION_PROTECTION,
            ...(isTravelWalletCreditsExperiment ? [PATH_TRAVEL_CREDITS] : []),
          ]}
          render={(props) => renderTerms(props.location.pathname)}
          exact
        />
        <Route path={PATH_CORP_FAQ} exact>
          <FAQ />
        </Route>
      </Switch>
    </Box>
  );
};

export default Body;

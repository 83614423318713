import { getConfigTenant } from "@capone/common";

// Use process.env when running Pawtucket modules on their own, otherwise use runtime config from McLean
export const BASE_URL = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_TYSONS_URL
  : "";

export const CORPORATE_PORTAL_URL = window.__mclean_env__
  ? window.__mclean_env__.CORPORATE_PORTAL_URL
  : process.env.CORPORATE_PORTAL_URL;

export const config = {
  // TODO implement auth when BE complete
  apiKey: "test",
  baseURL: BASE_URL!,
  corporatePortalUrl: CORPORATE_PORTAL_URL || "",
  TENANT: getConfigTenant(),
};

export const PATH_HOME = "/terms/";

export const PATH_PRICE_DROP_PROTECTION = `${PATH_HOME}price-drop-protection/`;
export const PATH_CANCEL_FOR_ANY_REASON = `${PATH_HOME}cancel-for-any-reason/`;
export const PATH_HOTEL_CANCEL_FOR_ANY_REASON = `${PATH_HOME}hotel-cancel-for-any-reason/`;
export const PATH_FROZEN_PRICES = `${PATH_HOME}frozen-prices/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_HOME}best-price-guarantee/`;
export const PATH_PRICE_PREDICTION_PRICE_WATCH = `${PATH_HOME}price-prediction-price-watch/`;
export const PATH_DISRUPTION_PROTECTION = `${PATH_HOME}rapid-rebook/`;
export const PATH_TRAVEL_OFFERS = `${PATH_HOME}travel-offers/`;
export const PATH_PREMIER_COLLECTION = `${PATH_HOME}premium-stays/`;
export const PATH_TRAVEL_CREDITS = `${PATH_HOME}travel-credits/`;
export const PATH_MISSED_CONNECTION_GUARANTEE = `${PATH_HOME}missed-connection-guarantee/`;

export const PATH_TERMS_OF_SERVICE = `/terms-of-service/`;
export const PATH_SITE_TERMS_OF_USE = `/site-terms-of-use/`;

export const PATH_HOTEL_PRICE_FREEZE = `${PATH_HOME}frozen-prices-hotel/`;

export const PATH_HOTEL_PRICE_DROP = `${PATH_HOME}hotel-price-drop-protection/`;

// Corporate travel
export const PATH_PRICE_MATCH_GUARANTEE = `${PATH_HOME}price-match-guarantee/`;

import React from "react";
import "./styles.scss";

export const UserManagementPermissionTable = () => (
  <table className="faq-user-management-permission-table" cellSpacing={0}>
    <thead>
      <tr>
        <th>Role \ Permission</th>
        <th>Book travel</th>
        <th>View users</th>
        <th>Approve travel</th>
        <th>View policy</th>
        <th>Edit policy</th>
        <th>Edit users</th>
        <th>View reports</th>
        <th>View all trips</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Admin</td>
        {Array(8)
          .fill("")
          .map((_, i) => (
            <td style={{ backgroundColor: "#6AA84F" }} key={i}>
              X
            </td>
          ))}
      </tr>
      <tr>
        <td>Traveler</td>
        <td style={{ backgroundColor: "#93C47D" }}>X</td>
        {Array(7)
          .fill("")
          .map((_, i) => (
            <td key={i} />
          ))}
      </tr>
    </tbody>
  </table>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LpyKnDCJOxtvtte7AQo\\+Bw\\=\\=.ptBaseModule-MuiTypography-root{\n  border-bottom: 1px solid var(--grey-5, #CECFCD);\n  font-family: Optimist;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 36px;\n  padding: 0 0 5px 0;\n  margin: 30px 0 25px 0;\n}\n\n.AXb-rQgDj4pbvRIpYIueyQ\\=\\= {\n  border-bottom: 1px solid var(--grey-5, #CECFCD);\n  position: absolute;\n  left: 0;\n  right: 0;\n}\n\n.wdtEfysPfcFy-ysUaHmc\\+w\\=\\= {\n  max-width: 790px;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./../../cap1-modules/terms-module/src/pages/capone-corporate/FAQ/styles.module.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".headingTitle:global(.ptBaseModule-MuiTypography-root){\n  border-bottom: 1px solid var(--grey-5, #CECFCD);\n  font-family: Optimist;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 36px;\n  padding: 0 0 5px 0;\n  margin: 30px 0 25px 0;\n}\n\n.tabContainer {\n  border-bottom: 1px solid var(--grey-5, #CECFCD);\n  position: absolute;\n  left: 0;\n  right: 0;\n}\n\n.container {\n  max-width: 790px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingTitle": "LpyKnDCJOxtvtte7AQo+Bw==",
	"tabContainer": "AXb-rQgDj4pbvRIpYIueyQ==",
	"container": "wdtEfysPfcFy-ysUaHmc+w=="
};
export default ___CSS_LOADER_EXPORT___;

import React, { ImgHTMLAttributes } from "react";

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export const Image = (props: ImageProps) => (
  <img
    {...props}
    alt={props.alt || ""}
    style={{ maxWidth: "100%", marginBottom: "1rem" }}
  />
);
